var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.loading ? _c('SpinnerLogo', {
    staticClass: "p-5"
  }) : _c('Pages', {
    attrs: {
      "title": "Monthly M&T Report"
    }
  }, [_c('Page', {
    attrs: {
      "title": "M&T Report",
      "sub-title": _vm.asset.siteName,
      "description": `${_vm.period.startDate.format('DD MMMM YYYY')} to ${_vm.period.endDate.format('DD MMMM YYYY')}`
    }
  }), _c('Page', {
    attrs: {
      "no-header": "",
      "no-footer-text": ""
    }
  }, [_c('div', {
    staticClass: "etn-report"
  }, [_c('TextBox', {
    staticClass: "mb-3",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Introduction")];
      },
      proxy: true
    }])
  }, [_vm._v(" In the context of the COVID-19 pandemic and recent energy crisis, changes in occupancy, as well as changes to plant operation are impacting energy consumption. A greater level of monitoring around energy use can help to track changes, identify significant impacts, highlight anomalies, and inform budget setting decisions. ")]), _c('TextBox', {
    staticClass: "mb-3",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Electricity Usage")];
      },
      proxy: true
    }])
  }, [_vm._v(" The below graph and table show the monthly variation in the electricity used over the last 12 months compared to the previous 12 month period, in terms of both absolute kWh and % variance. ")]), _c('AccountMonthlyConsumption', {
    staticStyle: {
      "height": "400px"
    },
    attrs: {
      "period": _vm.period,
      "data": _vm.combinedMonthlyConsumption,
      "loading": _vm.analyticsLoading.getAnalytics.consumptionMonthlyCurrent,
      "units": "kWh"
    }
  }), _c('TextBox', {
    staticClass: "mb-3"
  }, [_vm._v("December's usage has increased from last month by 1,780.7 kWh.")])], 1)]), _c('Page', {
    attrs: {
      "no-header": "",
      "no-footer-text": ""
    }
  }, [_c('div', {
    staticClass: "etn-report"
  }, [_c('AnnualComparisonTable', {
    attrs: {
      "period": _vm.period,
      "data": _vm.combinedMonthlyConsumption,
      "units": "kWh"
    }
  }), _c('TextBox', {
    staticClass: "mb-3"
  }, [_vm._v("Compared to the same period last year December used 1206 kWh more energy this month.")])], 1)]), _c('Page', {
    attrs: {
      "no-header": "",
      "no-footer-text": ""
    }
  }, [_c('div', {
    staticClass: "etn-report"
  }, [_c('TextBox', {
    staticClass: "mb-3",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Yearly Cumulative Usage Comparison")];
      },
      proxy: true
    }])
  }, [_vm._v(" The below graph shows the yearly cumulative usage pattern over the past 12 months compared to the previous 12 month period. ")]), _c('MonthlyCumulativeConsumption', {
    staticStyle: {
      "height": "500px"
    },
    attrs: {
      "period": _vm.period,
      "data": _vm.combinedMonthlyConsumption,
      "units": "kWh"
    }
  })], 1)]), _c('Page', {
    attrs: {
      "no-header": "",
      "no-footer-text": ""
    }
  }, [_c('div', {
    staticClass: "etn-report"
  }, [_c('TextBox', {
    staticClass: "mb-3",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Maximum Demand Analysis")];
      },
      proxy: true
    }])
  }, [_vm._v(" The below graph shows the maximum demand profile over the past month, providing a weekday and weekend average as well as the daily profile for the period of highest demand. ")]), _vm.analytics.consumptionHH.accountId ? _c('AccountHourlyDemand', {
    staticClass: "mb-3",
    staticStyle: {
      "height": "500px"
    },
    attrs: {
      "period": _vm.period,
      "data": _vm.analytics.consumptionHH.data,
      "units": "kWh"
    }
  }) : _vm._e(), _c('TextBox', {
    staticClass: "mb-3"
  }, [_vm._v(" The weekday average is displaying a much higher profile than during the weekends, as would be expected for an office block with reduced activity over the weekends. Start up generally occurs around 07:00 and shut down just after 16:00. Maximum demand was recorded on 04/12/2023 just after start up. ")])], 1)]), _c('Page', {
    attrs: {
      "no-header": "",
      "no-footer-text": ""
    }
  }, [_c('div', {
    staticClass: "etn-report"
  }, [_c('TextBox', {
    staticClass: "mb-3",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Electricity Usage Profile")];
      },
      proxy: true
    }])
  }, [_vm._v(" The below graphs show the electricity half hourly profile for the main incoming supply over the previous month. ")]), _vm.analytics.consumptionHH.accountId ? _c('HourlyProfile', {
    staticStyle: {
      "height": "500px"
    },
    attrs: {
      "period": _vm.period,
      "data": _vm.analytics.consumptionHH.data,
      "units": "kWh"
    }
  }) : _vm._e(), _c('TextBox', {
    staticClass: "mb-3"
  }, [_vm._v(" This months usage profile shows a strong weekday/weekend trend with clear start up and shut down periods for each day. Small spikes in usage tend to occur overnight most days. ")])], 1)]), _c('Page', {
    attrs: {
      "no-header": "",
      "no-footer-text": ""
    }
  }, [_c('div', {
    staticClass: "etn-report"
  }, [_c('TextBox', {
    staticClass: "mb-3",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_vm._v("Surface Mapping Analysis")];
      },
      proxy: true
    }])
  }, [_vm._v(" The below graph tracks usage patterns through a colour coded system whereby periods of high demand are indicated with warmer colours and periods of low demand with cooler colours. ")]), _vm.analytics.consumptionHH.accountId ? _c('HourlyConsumptionHeatmap', {
    staticClass: "mb-3",
    staticStyle: {
      "height": "600px"
    },
    attrs: {
      "period": _vm.period,
      "data": _vm.analytics.consumptionHH.data,
      "units": "kWh"
    }
  }) : _vm._e(), _c('TextBox', {
    staticClass: "mb-3"
  }, [_vm._v(" The periods of highest demand can usually be seen just after start up when most of the buildings electrical equipment will be switching on. ")])], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }